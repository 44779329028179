
// :root{
//     --color-pink: #F3C;
// } 

$primary-color: #16b3d5;
$success-color: #4caf50;
$error-color: #f44336;
$active-color: #6b778c;
$text-primary: #0f265c;
$text-secondary: #909090;
$warning-color: #ff9800;
$icon-color: #9CA2A4;

$primary-font: 'Nunito', sans-serif; // weights 400 700
:export {
    primaryColor: $primary-color;
    successColor: $success-color;
    errorColor: $error-color;
    primaryFont: $primary-font;
    activeColor: $active-color;
    textPrimaryColor: $text-primary;
    textSecColor: $text-secondary;
    warningColor: $warning-color;
}
