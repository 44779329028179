
@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media only screen and (max-width: 30em) { @content };    //480px
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 47.9375em) { @content };    //767px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 61.875em) { @content };     //900px
    }
    @if $breakpoint == large-tab-port {
        @media only screen and (max-width: 991px) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}

@mixin flexCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flexVerticalCenter {
    display: flex;
    align-items: center;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}