@import "style/custom.scss";

.loginFormButtonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    display: block;
    text-align: center;
  }
}
.loginFormlink {
  @media screen and (max-width: 769px) {
    text-align: center;
    margin-top: 10px;
  }
}