@import "./sass/mixins.scss";
@import "./sass/variables";

.frm-grp {
  margin-bottom: 1.3rem;
  &.half {
    width: 48%;
    display: inline-block;
  }
  &.right {
    float: right;
  }
}

.mr-15 {
  margin-right: 15px;
}

.flexCenter {
  @include flexCenter;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.theme_btn {
  background: linear-gradient(90deg, #16b3d5 2.47%, #05c4ab 100%);
  border-radius: 40px;
}

.outline_btn {
  font-weight: 400;
  border-radius: 50px;
  &:hover {
    background-color: $primary-color;
    color: #fff;
  }
}

.success {
  color: $success-color;
}

.error {
  color: $error-color;
}

.successActive {
  &::before {
    // content: " \25CF";
    // // font-size: 16px;
    // color: #15f446;
    // position: absolute;
    // right: 15%;
    // top: 0;
    position: absolute;
    right: 12%;
    top: 28%;
    content: "" !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #15f446;
    background: #15f446;
  }
}
.inActive {
  &::before {
    // content: " \25CF";
    // // font-size: 16px;
    // color: #a7b5c6;
    // position: absolute;
    // right: 15%;
    // top: 0;
    position: absolute;
    right: 12%;
    top: 28%;
    content: "" !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #a7b5c6;
    background: #a7b5c6;
  }
}

.errorActive {
  &::before {
    content: " \25CF";
    font-size: 20px;
    color: red;
  }
}
.commanBtnStyle {
  border-radius: 40px;
}
.settingLabel {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 4px 0;
}
table {
  tbody {
    td {
      min-width: 150px;
      max-width: 300px;
    }
  }
}
.error {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  // margin-left: 14px;
  color: #f44336;
}

// React Quill Style
.custonReactQuill {
  .quill {
    height: 230px;
    margin-top: 10px;
    .ql-container {
      height: 180px;
    }
  }
}
.desciptionHtmlForm {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.link {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #909090;
  text-decoration: none;
}
.accordianStyle {
  &::before {
    background-color: none !important;
  }
  .MuiAccordion-root {
    &:first-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
// .adminCard {
// }
.AddDrawerPapper {
  min-width: 75vh;
  max-width: 50%;
}
.settingAccordian {
  .MuiPaper-root {
    border-radius: 0px !important;
  }
  .MuiAccordion-root {
    border-radius: 16px !important;
    margin-bottom: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    &::before {
      background-color: transparent;
    }
  }
}
.DeviceSheduletabStyle {
  @media screen and (max-width: 769px) {
    width: 100%;
    .MuiTabs-root {
      .MuiButtonBase-root {
        width: 125px;
        word-wrap: break-word;
        .successActive,
        .inActive {
          &::before {
            font-size: 15px;
            right: 18%;
          }
        }
        .MuiTypography-root {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 390px) {
    width: 100%;
    .MuiTabs-root {
      .MuiButtonBase-root {
        width: 100px;
        font-size: 12px;
        word-wrap: break-word;
        .successActive,
        .inActive {
          &::before {
            font-size: 15px;
            right: 18%;
            top: 15%;
          }
        }
        .MuiTypography-root {
          font-size: 12px;
        }
      }
    }
  }
}
.customCardStyle {
  .MuiPaper-root {
    border-radius: 16px !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgb(241, 241, 241);
  }
}
.customCardRelayStyle {
  .MuiPaper-root {
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgb(241, 241, 241);
  }
}
.customCardSheduleStyle {
  .MuiPaper-root {
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgb(241, 241, 241);
  }
}
.customCardTableStyle {
  .MuiPaper-root {
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgb(241, 241, 241);
  }
}
.otpCustomStyleInput {
  justify-content: center;
  margin: 15px 0;
  input {
    width: 32px !important;
    height: 40px !important;
    // border-radius: 6px;
  }
}
.drawerStyl {
  min-width: 45%;
  max-width: 45%;
}
// .navLabel {
//   .MuiListSubheader-root {
//     padding: 0 0 10px !important;
//   }
// }
.navitemBtn {
  &.active {
    svg {
      g {
        fill: #16b3d5 !important;
      }
      path {
        fill: #16b3d5 !important;
      }
    }
  }
}
.navitemBtnTwo {
  &.active {
    svg {
      g {
        stroke: #16b3d5 !important;
      }
      path {
        stroke: #16b3d5 !important;
      }
    }
  }
}
.loderWraper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 11111;
}
// .resetAutocomplete{
//   .MuiAutocomplete-endAdornment	{
//     // right: 20px;
//   }
// }
.waitTimeClass {
  .MuiSwitch-track {
    background-color: orange !important;
  }
}

.infoDilog {
  background-color: #013865;
  color: #ffffff;
}
